.rfa-select-form-container {

  /*Override der mat-select komponente*/
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-select-value-text {
    color: white;
  }

  .mat-mdc-select-placeholder {
    color: white;
  }

  .mat-mdc-select-arrow svg {
    margin-top: 7px;
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    color: white;
  }
}

.rfa-select-form {
  max-width: 100px;
  margin-left: 15px;
  margin-top: 25px;
}

.rfa-select {
  font-size: var(--medium-font);
}

